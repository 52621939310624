import {Component, OnInit} from '@angular/core';
import {CallbackComponent} from "../../callback.component";
import {DeviceProfileCallback, FRDevice} from "@forgerock/javascript-sdk";

@Component({
  selector: 'app-callback-device-profile',
  templateUrl: './device-profile.component.html',
  styleUrls: ['./device-profile.component.scss']
})
export class DeviceProfileComponent extends CallbackComponent {

  override callback: DeviceProfileCallback

  override ngOnInit(): void {
    const device = new FRDevice()
    device.getProfile({
      location: this.callback.isLocationRequired(),
      metadata: this.callback.isMetadataRequired(),
    }).then((profile) => {
      console.debug("device profile is", profile)
      this.callback?.setProfile(profile)
      this.loginWithEmitter.emit({resetStep: false, service: ""})
    }).catch(() => {
      console.error("unable to retrieve device profile")
    })
  }
}
