import {Component} from "@angular/core"
import {CallbackComponent} from "../../callback.component"
import {CallbackType, FRAuth, RedirectCallback} from "@forgerock/javascript-sdk"
import {MessageService} from "primeng/api"
import {TranslateService} from "@ngx-translate/core"

@Component({
  selector: 'app-callback-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent extends CallbackComponent {

  override callback: RedirectCallback

  constructor(private translate: TranslateService, private messageService: MessageService) {
    super()
  }

  override ngOnInit(): void {
    this.messageService.add({
      severity: "info",
      summary: "Info",
      detail: this.translate.instant("REDIRECT")
    })
    const redirectCallbacks = this.step?.getCallbacksOfType(
      CallbackType.RedirectCallback
    )
    if (this.step && redirectCallbacks && redirectCallbacks.length > 0) {
      FRAuth.redirect(this.step)
    }
  }
}







