<section class="page_404">
	<div class="container">
		
		<div class="card col-6 col-offset-3">
			<app-banner></app-banner>
		<div class="row">	
		<!-- <div class="col-sm-12 "> -->
		<div class="col-sm-10 col-sm-offset-1  text-center">
		<div class="four_zero_four_bg">
			<h1 class="text-center ">404</h1>
		
		
		</div>
		
		<div class="contant_box_404">
		<!-- <h3 class="h2">
		Look like you're lost
		</h3> -->
		
		<p>{{'PAGE_NOT_FOUND.MAIN'|translate}}</p>
		
		<!-- <a href="" class="link_404">Go to Home</a> -->
        <a [href]="fcpUrl" class="link_404" style="cursor: pointer">{{'PAGE_NOT_FOUND.REDIRECT_LOGIN'|translate}} </a>
	</div>
		</div>
		</div>
		</div>
	</div>
</section>