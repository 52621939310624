<div class="field p-field p-fluid">
  <label for="{{ 'callback_' + index }}">
    {{ step.getStage() + '_callback_' + index | uppercase | translate }}
  </label>
  <input (input)="onInputChange()"
         [class.p-error]="!ff.valid && ff.touched"
         [formControl]="ff"
         autocomplete="current-password"
         id="{{ 'callback_' + index }}"
         novalidate
         pInputText
         required
         type="password"
  >
  <ng-container *ngIf="!ff.valid && ff.touched">
    <p *ngIf="ff.errors!['required']"
       [innerHTML]="'REQUIRED_FIELD_ERROR'|translate: { FIELD_NAME:  (step.getStage()|uppercase) + '_CALLBACK_' + index |translate  |lowercase } "
       style="color:red"></p>
    <span *ngIf="ff.errors!['minlength']"
          style="color:red">{{"PASSWORDRESET_SETPASSWORD_INSTRUCTIONS_1"|translate}} {{"PASSWORDRESET_SETPASSWORD_INSTRUCTIONS_2"|translate}}</span>
  </ng-container>
</div>

