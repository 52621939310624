import { Component } from '@angular/core';
import {StageComponent} from "../../../stage.component";

@Component({
  selector: 'app-error-location-not-shared',
  templateUrl: './error-location-not-shared.component.html',
  styleUrls: ['./error-location-not-shared.component.scss']
})
export class ErrorLocationNotSharedComponent extends StageComponent {

}
