import { Component } from '@angular/core';
import {StageComponent} from "../../../stage.component";

@Component({
  selector: 'app-password-reset-token-not-sent',
  templateUrl: './token-not-sent.component.html',
  styleUrls: ['./token-not-sent.component.scss']
})
export class TokenNotSentComponent extends StageComponent {

}
