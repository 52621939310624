<div>
  <div class="card col-12">
    <div class="flex flex-column">
      <form ngNativeValidate>
        <ng-template appCallback></ng-template>
        <div>
          <div class="pi pi-inbox big-icon"></div>
          <div>
            <h3>{{ "PASSWORDRESET_INVALID_PASSWORD" | translate }}</h3>
          </div>
        </div>
      <button pButton
              (click)="loginWith('', true)"
              class="w-full"
              [label]="'BACK_TO_THE_HOME_PAGE' | translate"
      >
      </button>
      </form>
    </div>
  </div>
</div>
