import {Component, OnInit} from '@angular/core';
import {CallbackComponent} from "../../callback.component";
import {HiddenValueCallback} from "@forgerock/javascript-sdk";

@Component({
  selector: 'app-callback-hidden-value',
  templateUrl: './hidden-value.component.html',
  styleUrls: ['./hidden-value.component.scss']
})
export class HiddenValueComponent extends CallbackComponent {

  override callback: HiddenValueCallback

  value: string | undefined

   override ngOnInit(): void {
    this.value = this.callback.getOutputValue() as string
  }

}
