<div class="field p-field p-fluid">
  <label for="{{ 'callback_' + index }}">
    {{ step.getStage() + '_callback_' + index | uppercase | translate }}
  </label>
  <input (input)="onInputChange()"
         [class.p-error]="!fc.valid && fc.touched"
         [formControl]="fc"
         autocomplete="username"
         id="{{ 'callback_' + index }}"
         pInputText
         required
         type="text">
  <p *ngIf="!fc.valid && fc.touched"
     [innerHTML]="'REQUIRED_FIELD_ERROR'|translate: { FIELD_NAME:  ((step.getStage()|uppercase) + '_FIELD_' + index) |translate  |lowercase } "
     style="color:red"></p>
</div>
