import { Component } from '@angular/core';
import {StageComponent} from "../../../stage.component";

@Component({
  selector: 'app-recover-username-mail-not-sent',
  templateUrl: './mail-not-sent.component.html',
  styleUrls: ['./mail-not-sent.component.scss']
})
export class MailNotSentComponent extends StageComponent {

}
