import {TranslateService} from "@ngx-translate/core";

interface Option {
  name: string,
  code: string
}

export abstract class BaseComponent {
  language: Option
  languageOptions: Option[]
  protected translate: TranslateService
  // language used when language can't be found in browser storage or
  // the browser language is not supported
  protected defaultLanguage = { name: "FR", code: "FR" }

  protected constructor(
    translate: TranslateService
  ) {
    this.translate = translate
    this.languageOptions = [
      { name: "EN", code: "EN" },
      { name: "FR", code: "FR" },
      { name: "NL", code: "NL" },
      { name: "DE", code: "DE" }
    ]
    this.language = this.getLanguage()
    this.useLang(this.language)
    this.translate.onLangChange.subscribe(() => {
      console.debug("currentLanguage is", this.translate.currentLang)
    })
  }

  /**
   * Use the language found in browser localStorage if found
   * Or use the language configured in the browser
   */
  getLanguage() : Option {
    if (localStorage.getItem("locale")) {
      const savedLang = localStorage.getItem("locale") || "FR"
      console.debug("using localStorageLanguage", savedLang)
      return {name: savedLang, code: savedLang}
    } else {
      const browerLang = this.translate.getBrowserLang()!.toUpperCase()
      if (this.languageOptions.find(o => o.code == browerLang)) {
        console.debug("using browserLanguage", browerLang)
        return {name: browerLang, code: browerLang}
      } else {
        console.debug("using default language", this.defaultLanguage)
        return this.defaultLanguage
      }
    }
  }

  /**
   * switch the language and store is in browser localStorage
   * @param language the new language
   */
  useLang(language: Option) {
    const code = language.code.toLowerCase()
    console.debug("Translate use:", code)
    this.translate.use(code)
    localStorage.setItem('locale', code.toUpperCase());
    console.log(this.language);
    
    this.language = language;
  }
  onLangChange(): void {
    console.debug("Language changed to ", this.language)
    this.useLang(this.language)
  }
}
