import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { Observable } from 'rxjs';


// Modules
import { AppRoutingModule } from './app-routing.module';

// Main components
import { AppComponent } from './app.component';
import { BannerComponent } from './banner/banner.component';
import { CallbackComponent } from './callback.component';
// Directives
import { CallbackDirective } from './callback.directive';
import { DeviceProfileComponent, HiddenValueComponent, MetadataComponent, NameComponent, PasswordComponent, RedirectComponent, SelectIdPComponent, TextOutputComponent } from './callbacks';
import { HttpLangInterceptor } from './http-lang.interceptor';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { StageComponent } from './stage.component';
import { StageDirective } from './stage.directive';
import {
  CollectDeviceProfileComponent,
  CollectOtpComponent,
  ErrorLocationNotSharedComponent,
  ErrorLocationOutsideBelgiumComponent,
  ErrorNoPhoneNumberComponent,
  ErrorUserNotFoundComponent,
  InvalidPasswordComponent,
  PasswordChangedComponent,
  SetPasswordComponent,
  TokenNotSentComponent,
  TokenSentComponent,
  UnableToSavePasswordComponent,
} from './stages';

// Stage components
import { CollectEmailComponent as PasswordResetCollectEmail } from './stages/password-reset/collect-email/collect-email.component';
import { MailNotSentComponent as RecoverUsernameMailNotSent } from './stages/recover-username/mail-not-sent/mail-not-sent.component';
import { MailSentComponent as RecoverUsernameMailSent } from './stages/recover-username/mail-sent/mail-sent.component';
import { UsernameCollectEmailComponent as RecoverUsernameCollectEmail } from './stages/recover-username/username-collect-email/username-collect-email.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


export class TranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, public prefix: string = '/assets/i18n/', public suffix: string = '.json') {
  }

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<Object> {
    const noCacheSuffix = '?prevent-caching=' + Math.random().toString();
    return this.http.get(`${ this.prefix }${ lang }${ this.suffix }${ noCacheSuffix }`);
  }
}

@NgModule({
  declarations: [
    // App components
    AppComponent,
    StageComponent,
    LoginPageComponent,
    LoginFormComponent,
    CallbackComponent,
    NameComponent,
    PasswordComponent,
    TextOutputComponent,
    RedirectComponent,
    HiddenValueComponent,
    SelectIdPComponent,
    DeviceProfileComponent,
    MetadataComponent,
    BannerComponent,
    PageNotFoundComponent,

    // App directives
    CallbackDirective,
    StageDirective,

    // Stage components: Password
    CollectDeviceProfileComponent,
    CollectOtpComponent,
    ErrorLocationNotSharedComponent,
    ErrorLocationOutsideBelgiumComponent,
    ErrorUserNotFoundComponent,

    // Stage components: PasswordReset
    PasswordResetCollectEmail,
    TokenSentComponent,
    TokenNotSentComponent,
    SetPasswordComponent,
    PasswordChangedComponent,
    InvalidPasswordComponent,
    UnableToSavePasswordComponent,
    ErrorNoPhoneNumberComponent,

    // Stage components: RecoverUsername
    RecoverUsernameCollectEmail,
    RecoverUsernameMailSent,
    RecoverUsernameMailNotSent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ],
      },
    }),
    // PrimeNG modules
    AccordionModule,
    AutoCompleteModule,
    BrowserAnimationsModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    ChipsModule,
    ConfirmDialogModule,
    ContextMenuModule,
    DialogModule,
    DropdownModule,
    FieldsetModule,
    FileUploadModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    ListboxModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OverlayPanelModule,
    PanelModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    RippleModule,
    SelectButtonModule,
    SliderModule,
    SplitButtonModule,
    TableModule,
    TabViewModule,
    TieredMenuModule,
    ToastModule,
    ToggleButtonModule,
    TooltipModule,
  ],
  providers: [
    MessageService, { provide: HTTP_INTERCEPTORS, useClass: HttpLangInterceptor, multi: true },
  ],

  bootstrap: [ AppComponent ],
})
export class AppModule {
}
