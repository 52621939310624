<img alt="4CP banner"
     src="{{ 'assets/images/banner-' + language.code.toLowerCase() + '.png' }}" width="100%">
<div class="flex my-5">
    <div class="flex-1 pt-1">
        <h4>{{ title | translate }}</h4>
    </div>
    <div class="flex" *ngIf="urllanguage.toLocaleLowerCase() === 'nl';else notNL">
        <a [attr.href]="faqLink + 'nl/faq'"
           class="text-teal-600 focus:text-teal-900 focus:text-secondary my-2 cursor-pointer"
           id="faq" target='_blank'>FAQ</a>
        <p-selectButton (onChange)="onLangChange()" [(ngModel)]="language"
                        [options]="languageOptions" optionLabel="name"></p-selectButton>
    </div>
    <ng-template #notNL>
        <div class="flex">
          <a [attr.href]="faqLink + 'fr/faq' "
             class="text-teal-600 focus:text-teal-900 focus:text-secondary my-2 cursor-pointer" 
             id="faq" target='_blank'>FAQ</a>
             <p-selectButton (onChange)="onLangChange()" [(ngModel)]="language"
             [options]="languageOptions" optionLabel="name"></p-selectButton>
            </div>
      </ng-template>
</div>
<p-messages
        [(value)]="msgs"
        [enableService]="true"
        class="paragraph-with-white-space"
></p-messages>
<div class="re-account-container" *ngIf="!isProd">
    <p class="warning">{{'LOGIN_CLEARED_ACCOUNT'|translate}}</p>
    <p>
        <a [attr.href]="registerLink + '?lang=' + urllanguage"
           class="warning bolder focus:text-secondary my-2 cursor-pointer">{{'LOGIN_CLEARED_ACCOUNT_CREATE_ACCOUNT'|translate}}</a>
    </p>
</div>

