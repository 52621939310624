import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FRStep } from '@forgerock/javascript-sdk';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { BaseComponent } from './base.component';
import { CallbackComponent } from './callback.component';
import { CallbackDirective } from './callback.directive';
import { DeviceProfileComponent, HiddenValueComponent, MetadataComponent, NameComponent, PasswordComponent, RedirectComponent, SelectIdPComponent, TextOutputComponent } from './callbacks';

@Component({ template: '' })
export class StageComponent extends BaseComponent {
  static callbackComponentMap: { [key: string]: any } = {
    NameCallback: NameComponent,
    PasswordCallback: PasswordComponent,
    TextOutputCallback: TextOutputComponent,
    RedirectCallback: RedirectComponent,
    HiddenValueCallback: HiddenValueComponent,
    SelectIdPCallback: SelectIdPComponent,
    DeviceProfileCallback: DeviceProfileComponent,
    MetadataCallback: MetadataComponent,
  };
  @Input() step: FRStep | undefined;
  @Input() loginFailed: boolean = false;
  @Input() internalError: boolean = false;
  @Input() amDown: boolean = false;
  @Input() authenticating: boolean = false;
  @Input() loginSuccess: boolean = false;
  @Output() loginWithEmitter = new EventEmitter<{ service: string, resetStep: boolean }>();
  @ViewChild(CallbackDirective, { static: true }) callbacks!: CallbackDirective;

  constructor(
    protected messageService: MessageService,
    protected route: ActivatedRoute,
    translate: TranslateService,
  ) {
    super(translate);
  }

  loginWith(service: string = '', resetStep: boolean = false) {
    console.debug('Emitting loginWith event with service', service);
    this.loginWithEmitter.emit({ service, resetStep });
  }

  loadCallbacks(step: FRStep) {
    console.debug('Rendering callbacks');
    for (const [ index, callback ] of step.callbacks.entries()) {
      const type: string = callback.payload.type;
      const component = StageComponent.callbackComponentMap[type];
      // console.debug('Rendering', type, 'with component', component, 'and index', index);
      const componentRef = this.callbacks.viewContainerRef.createComponent<CallbackComponent>(component);
      componentRef.instance.index = index;
      componentRef.instance.callback = callback;
      componentRef.instance.step = step;
      componentRef.instance.loginWithEmitter.subscribe(
        (event) => {
          // console.debug('loginWithEmitter stage event');
          let { service, resetStep } = event;
          this.loginWith(service, resetStep);
        });
    }
  }

  clearCallback() {
    // clearing previous view, with previous step callbacks
    this.callbacks?.viewContainerRef.clear();
  }
}
