import {Component, OnInit} from '@angular/core';
import {CallbackComponent} from "../../callback.component";
import {MetadataCallback} from "@forgerock/javascript-sdk";
import {TranslateService} from "@ngx-translate/core";
import {MessageService} from "primeng/api";

type DataMap = {
  [id: string]: string;
}

@Component({
  selector: 'app-callback-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss']
})
export class MetadataComponent extends CallbackComponent {

  override callback: MetadataCallback

  constructor(private translate: TranslateService, private messageService: MessageService) {
    super()
  }

  override ngOnInit(): void {
    const data: DataMap = this.callback.getData()
    console.debug("Received metadata", data)
    if (data.hasOwnProperty("error_message")) {
      console.debug("Found error message", data)
      data['error_message'].split(",").forEach(m =>
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant("ERROR"),
          detail: this.translate.instant(m.toUpperCase())
        }))
    }
    if (data.hasOwnProperty("warning_message")) {
      console.debug("Found warning message", data)
      data['warning_message'].split(",").forEach(m =>
        this.messageService.add({
          severity: "warn",
          summary: this.translate.instant("WARNING"),
          detail: this.translate.instant(m.toUpperCase())
        }))
    }
  }
}
