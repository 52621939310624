import {Component} from '@angular/core';
import {StageComponent} from "../../../stage.component";

@Component({
  selector: 'app-collect-otp',
  templateUrl: './collect-otp.component.html',
  styleUrls: ['./collect-otp.component.scss']
})
export class CollectOtpComponent extends StageComponent {

}
