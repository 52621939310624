import { Component } from '@angular/core';
import {StageComponent} from "../../../stage.component";

@Component({
  selector: 'app-password-reset-collect-email',
  templateUrl: './collect-email.component.html',
  styleUrls: ['./collect-email.component.scss']
})
export class CollectEmailComponent extends StageComponent {

}
