import { Component, OnInit } from '@angular/core';
import {StageComponent} from "../../../stage.component";

@Component({
  selector: 'app-error-no-phone-number',
  templateUrl: './error-no-phone-number.component.html',
  styleUrls: ['./error-no-phone-number.component.scss']
})
export class ErrorNoPhoneNumberComponent extends StageComponent {


}
