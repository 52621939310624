<div>
  <div class="card col-12" style="padding:0.5rem">
    <div class="flex flex-column col-7 " style="margin-left: auto;margin-right: auto">
      <form (ngSubmit)="loginWith()" autocomplete="off" ngNativeValidate>
        <ng-template appCallback></ng-template>
        <div class="flex justify-content-evenly flex-wrap my-3">
          <button [disabled]="authenticating || loginSuccess"
            [label]="loginFailed ? ('RETRY' | translate) : ('LOGIN' | translate)" class="w-full custom-margin" pButton
            type="submit">
          </button>
          <a [attr.href]="registerLink + '?lang=' + urllanguage"
            class="text-teal-600 focus:text-teal-900 focus:text-secondary my-2 cursor-pointer"
            id="noaccount">{{'NO_ACCOUNT_?' | translate}} </a>
          <a (click)="loginWith('passwordReset', true)"
            class="text-teal-600 focus:text-teal-900 focus:text-secondary my-2 cursor-pointer">
            {{ "FORGOT_YOUR_PASSWORD_?" | translate }}
          </a>
          <a (click)="loginWith('recoverUsername', true)"
            class="text-teal-600 focus:text-teal-900 focus:text-secondary my-2 cursor-pointer">
            {{ "FORGOT_YOUR_USERNAME_?" | translate }}
          </a>
        </div>

        <div class="centerd-faq-container" *ngIf="urllanguage.toLocaleLowerCase() === 'nl';else notNL">
          <a [attr.href]="faqLink + 'nl/faq'"
            class="text-teal-600 focus:text-teal-900 focus:text-secondary my-2 cursor-pointer"
            target='_blank'>{{'FAQ_2'|translate}}</a>
        </div>
        <ng-template #notNL>
          <div class="centerd-faq-container">
            <a [attr.href]="faqLink + 'fr/faq' "
              class="text-teal-600 focus:text-teal-900 focus:text-secondary my-2 cursor-pointer"
              target='_blank'>{{'FAQ_2'|translate}}</a>
          </div>
        </ng-template>
      </form>
    </div>
  </div>
</div>

<div class="card col-12 row d-flex justify-content-center" style="padding:0rem;display:flex;flex-wrap:wrap">
  <span class="col-md-6 d-inline-flex" style="flex:1" *ngIf="union === '500';else not500">
    <p style="display:flex;align-items:center;"><a [href]="otherMutLink"
        class="text-black-600 focus:text-600 focus:text-secondary my-2 cursor-pointer">
        <img max-width="10px" style="vertical-align:middle;max-width:45px;margin-left:80px" alt="image mut200"
          src='assets/images/logo200.png' max-width="10%"><div style="display:inline-block;">{{'SWITCH_UNION'|translate : {union: (union === '500' ?
          'UNION.NEUTRE' :
          'UNION.LIBRE')|translate} }}</div></a></p>
  </span>
  <ng-template #not500>
    <span class="col-md-6 d-inline-flex" style="flex:1">
      <p style="display:flex;align-items:center"><a [href]="otherMutLink"
          class="text-black-600 focus:text-600 focus:text-secondary my-2 cursor-pointer">
          <img max-width="10px"
            style="vertical-align:middle;max-width:25px;margin-left:80px;margin-right:15px"
            alt="image mut500" src='assets/images/logo500.png' max-width="10%"><div style="display:inline-block">{{'SWITCH_UNION'|translate : {
            union: (union === '500'
            ? 'UNION.NEUTRE' : 'UNION.LIBRE')|translate} }}
            </div></a></p>
    </span>
  </ng-template>
  <span class="col-md-6 d-inline-flex" style="padding:0rem;display:flex;flex-wrap:wrap"
    *ngIf="union === '400';else not400">
    <p style="display:flex;align-items:center;text-align:left"><a [href]="otherMutLink1"
        class="text-black-600 focus:text-600 focus:text-secondary my-2 cursor-pointer" style="margin-right:80px">
        <img style="vertical-align:middle;margin-right:-10px;max-width:80px" alt="image mut200"
          src='assets/images/logo200.png' max-width="10%" style="vertical-align: middle;max-width:50px;margin-right:10px;margin-top:-10px;"><span>{{'SWITCH_UNION'|translate : {union: (union === '400' ?
          'UNION.NEUTRE' : 'UNION.LIBERALES')|translate} }}</span></a></p>
  </span>
  <ng-template #not400>
    <span *ngIf="urllanguage.toLocaleLowerCase() !== 'nl';else notNL" class="col-md-6 d-inline-flex"
      style="padding:0rem;display:flex;flex-wrap:wrap">
      <p style="display:flex;align-items:center;text-align:left"><a [href]="otherMutLink1"
          class="text-black-600 focus:text-600 focus:text-secondary my-2 cursor-pointer" style="margin-right:80px">
          <img style="vertical-align:middle;margin-right:20px;max-width:45px" alt="image mut400"
            src='assets/images/logo400_fr.png' max-width="10%"
            style="vertical-align: middle;max-width:35px;margin-right:10px;">{{'SWITCH_UNION'|translate : {union: (union
          ===
          '400' ? 'UNION.NEUTRE' : 'UNION.LIBERALES')|translate} }}</a></p>
    </span>
    <ng-template #notNL>
      <span class="col-md-6 d-inline-flex"
        style="padding:0rem;display:flex;flex-wrap:wrap">
        <p style="display:flex;align-items:center;text-align:left"><a [href]="otherMutLink1"
            class="text-black-600 focus:text-600 focus:text-secondary my-2 cursor-pointer" style="margin-right:80px">
            <img style="vertical-align:middle;margin-right:20px;max-width:45px" alt="image mut400"
              src='assets/images/logo400_nl.PNG' max-width="10%"
              style="vertical-align: middle;max-width:35px;margin-right:10px;"><span>{{'SWITCH_UNION'|translate :
              {union: (union ===
              '400' ? 'UNION.NEUTRE' : 'UNION.LIBERALES')|translate} }}</span></a></p>
      </span>
    </ng-template>
  </ng-template>
</div>