import { Component } from '@angular/core';
import {StageComponent} from "../../../stage.component";

@Component({
  selector: 'app-password-reset-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent extends StageComponent {

}
