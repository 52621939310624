import {FRCallback, FRStep} from "@forgerock/javascript-sdk"
import {Component, EventEmitter, OnInit, Output} from "@angular/core";


@Component({
  template: ``
})
export class CallbackComponent implements OnInit{


  @Output() loginWithEmitter = new EventEmitter<{ service: string, resetStep: boolean }>();

  index: number
  callback: FRCallback
  step: FRStep
  id: number | undefined

  ngOnInit(): void {
    console.debug("should be overridden by extended method")
  }
}
