import { Component } from '@angular/core';
import {StageComponent} from "../../../stage.component";

@Component({
  selector: 'app-recover-username-token-sent',
  templateUrl: './mail-sent.component.html',
  styleUrls: ['./mail-sent.component.scss']
})
export class MailSentComponent extends StageComponent {

}
