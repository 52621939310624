import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PasswordCallback } from '@forgerock/javascript-sdk';

import { CallbackComponent } from '../../callback.component';

@Component({
  selector: 'app-callback-password',
  templateUrl: './password.component.html',
  styleUrls: [ './password.component.scss' ],
})
export class PasswordComponent extends CallbackComponent {
  ff: FormControl;
  override callback: PasswordCallback;

  override ngOnInit() {
    super.ngOnInit();
    this.ff = new FormControl('', this.step.getStage() === 'usernamePassword_collectCredentials' ? [ Validators.required, Validators.minLength(8) ] : [ Validators.required ]);
  }

  onInputChange(): void {
    if (this.ff.valid) {
      this.callback.setPassword(this.ff.value!);
    }
  }

}
