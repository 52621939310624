import { Component, OnInit, Type, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Config, FRAuth, FRCallback, FRLoginFailure, FRLoginSuccess, FRStep, StepType } from '@forgerock/javascript-sdk';
import { Action, RequestObj } from '@forgerock/javascript-sdk/lib/config/interfaces';
import { StringDict } from '@forgerock/javascript-sdk/lib/shared/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { environment } from '../../environments/environment';
import { BaseComponent } from '../base.component';
import { ApplicationSettingsService } from '../config/application-settings.service';
import { LoginFormComponent } from '../login-form/login-form.component';
import { StageComponent } from '../stage.component';
import { StageDirective } from '../stage.directive';
import {
  CollectDeviceProfileComponent,
  CollectEmailComponent as PasswordResetCollectEmail,
  CollectOtpComponent,
  ErrorLocationNotSharedComponent,
  ErrorLocationOutsideBelgiumComponent,
  ErrorNoPhoneNumberComponent,
  ErrorUserNotFoundComponent,
  InvalidPasswordComponent,
  MailNotSentComponent as RecoverUsernameMailNotSent,
  MailSentComponent as RecoverUsernameMailSent,
  PasswordChangedComponent,
  SetPasswordComponent,
  TokenNotSentComponent,
  TokenSentComponent,
  UnableToSavePasswordComponent,
  UsernameCollectEmailComponent as RecoverUsernameCollectEmail,
} from '../stages';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: [ './login-page.component.scss' ],
})
export class LoginPageComponent extends BaseComponent implements OnInit {
  matomoCode: string;

  static stageComponentMap: { [key: string]: any } = {
    'recoverUsername_collectEmail': RecoverUsernameCollectEmail,
    'recoverUsername_unable_to_request_username': RecoverUsernameMailNotSent,
    'recoverUsername_mail_sent': RecoverUsernameMailSent,
    'passwordReset_collectEmail': PasswordResetCollectEmail,
    'passwordReset_token_sent': TokenSentComponent,
    'passwordReset_unable_to_request_token': TokenNotSentComponent,
    'passwordReset_setPassword': SetPasswordComponent,
    'passwordReset_password_saved': PasswordChangedComponent,
    'passwordReset_invalid_password': InvalidPasswordComponent,
    'passwordReset_unable_to_save_password': UnableToSavePasswordComponent,
    'usernamePassword_collectDeviceProfile': CollectDeviceProfileComponent,
    'usernamePassword_collectOtp': CollectOtpComponent,
    'usernamePassword_error_locationNotShared': ErrorLocationNotSharedComponent,
    'usernamePassword_error_outsideBelgium': ErrorLocationOutsideBelgiumComponent,
    'usernamePassword_error_noPhoneNumber': ErrorNoPhoneNumberComponent,
    'fas_error_userNotFound': ErrorUserNotFoundComponent,
  };

  @ViewChild(StageDirective, { static: true }) stage!: StageDirective;

  step: FRStep | FRLoginSuccess | FRLoginFailure | undefined;
  loginFailed: boolean = false;
  internalError: boolean = false;
  amDown: boolean = false;
  authenticating: boolean = false;
  loginSuccess: boolean = false;
  queryService: undefined;

  constructor(
    protected messageService: MessageService,
    protected route: ActivatedRoute,
    translate: TranslateService,
    private applicationSettingsServices: ApplicationSettingsService) {
    super(translate);
  }

  ngOnInit(): void {
    // Initial authenticate request, when getting into the application
    this.route.queryParams
      .subscribe(params => {
          console.log(params);
          this.queryService = params['service'];
          console.log('Service', this.queryService);
        },
      );
    this.step = undefined;
    console.debug('route queryParams: ', this.route.queryParams);
    this.setConfig(this.queryService);
    this.authenticate();
  }

  loginWith(service: string = '', resetStep: boolean = false) {
    this.setConfig(service);
    if (resetStep) {
      // resetting previous step to start a new flow
      this.step = undefined;
    }
    this.authenticate(true);
  }

  addHeadersMiddleware = (
    req: RequestObj,
    action: Action,
    next: () => RequestObj,
  ): void => {
    switch (action.type) {
      case 'AUTHENTICATE':
        const headers = req.init.headers as Headers;

        headers.append('Accept-Language', this.translate.currentLang.toLowerCase());
        break;
    }
    next();
  };

  setConfig(service: string = ''): void {
    console.log('Setting config with environment', environment);
    // set forgerock configuration
    Config.set({
      middleware: [
        this.addHeadersMiddleware,
      ],
      serverConfig: {
        baseUrl: this.applicationSettingsServices.getPropertyValue('amBaseUrl'),
        timeout: parseInt(this.applicationSettingsServices.getPropertyValue('amTimeoutSeconds')) * 1000,
      },
      realmPath: this.applicationSettingsServices.getPropertyValue('amRealm'),
      tree: service,
    });
  }

  authenticate(userAction = false): void {
    this.messageService.clear();
    this.clearStage();
    console.debug(
      'Authenticate',
      // step.value
    );
    if (userAction) {
      this.loginFailed = false;
    }
    let resume = false;
    // Note: FRAuth.redirect() stores the previous step in local storage,
    // therefore we only resume if a previous step is required.
    // Note that FRAuth will add/remove the step from localStorage itself
    // when using next(), redirect() and resume().
    if (this.requiresPreviousStep()) {
      // enabling resume only if the previousStep is there, otherwise there is nothing to resume !
      resume = localStorage.getItem(FRAuth.previousStepKey) !== null;
    } else {
      // It might happen that we still have a previous step in the localStorage that is not required.
      // If the SDK (through resume) failed to clean up properly, we do it manually
      // here to avoid the application looping through authenticate by constantly
      // trying to resume a flow that does not require a previous step.
      localStorage.removeItem(FRAuth.previousStepKey);
    }

    this.nextStep(
      this.step,
      resume,
      this.getQuery(),
    ).catch((e: Error) => {
      console.error('nextStep failed:', e);
    });
    console.debug('authenticate finished, current step: ', this.step);
  }

  getQuery() {
    const queryParams: Params = this.route.snapshot.queryParams;
    const query: StringDict<string> = {};
    for (const q in queryParams) {
      query[q] = queryParams[q];
    }
    console.debug('Parsed query params', query);
    return query;
  }

  requiresPreviousStep(): boolean {
    const parsedUrl = new URL(window.location.href);
    const code = parsedUrl.searchParams.get('code');
    const form_post_entry = parsedUrl.searchParams.get('form_post_entry');
    const state = parsedUrl.searchParams.get('state');
    const requires: boolean =
      (code !== null && state !== null) || form_post_entry !== null;
    console.debug('Requires previous step', requires);
    return requires;
  }

  async nextStep(
    previousStep: FRStep | FRLoginSuccess | FRLoginFailure | undefined,
    resume: boolean = false,
    query: StringDict<string> | undefined = undefined,
  ) {
    this.internalError = false;
    this.amDown = false;
    this.authenticating = true;
    this.loginSuccess = false;
    if (previousStep !== undefined) {
      console.debug('previousStep payload', previousStep.payload);
      // } else {
      //   console.debug("previousStep is empty")
    }
    if (resume) {
      await this.resumeStep(query);
    } else {
      // previousStep step is FRStep or undefined here.
      console.debug('Next-ing!');
      this.step = await FRAuth.next(previousStep as FRStep, {
        query,
      });
    }
    this.authenticating = false;
    // @ts-ignore
    if (this.step?.payload && this.step.payload?.status > 499) {
      this.amDown = true;
      this.messageService.add({
        severity: 'error',
        summary: this.translate.instant('error'),
        detail: this.translate.instant('amDown'),
      });
    }
    switch (this.step?.type) {
      case StepType.LoginSuccess:
        this.loginSuccess = true;
        console.debug(
          'Step reached: LoginSuccess with successUrl = ',
          this.step.getSuccessUrl(),
        );
        window.location.href = this.step.getSuccessUrl() as string;
        break;
      case StepType.LoginFailure:
        console.debug('Step reached: LoginFailure');
        // even if login failed, we keep the step, so that when the next
        // authentication is triggered, we get the next step of the tree
        this.loginFailed = true;
        const responseMessage = this.step.payload.message;
        // If no response message, show default login failed one.
        const detailMessage = responseMessage ? responseMessage : 'loginFailed';
        if (responseMessage) {
          console.error(responseMessage);
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('error'),
            detail: this.translate.instant(detailMessage),
          });
        }

        break;
      case StepType.Step:
        const stage: string | undefined = (this.step).getStage();
        console.debug(
          'Step reached:',
          this.step.type,
          ' with stage = ',
          stage,
        );
        if (stage) {
        }
        const foundCallacks: boolean = this.foundCallbacks(this.step);
        // If authenticate returns some callbacks, we must render them
        if (!foundCallacks) {
          console.debug('No callbacks found, continuing..');
          await this.nextStep(this.step);
        } else {
          console.debug('Found callbacks, loading components waiting for user input');
          await this.loadStage(<string> stage, this.step);
        }
        break;
      default:
        console.warn('Step is undefined or has not type..');
        break;
    }
  }


  async resumeStep(query: StringDict<string> | undefined = undefined) {
    try {
      console.debug('Resuming!');
      this.step = await FRAuth.resume(window.location.href, {
        query,
      });
    } catch (e) {
      this.authenticating = false;
      this.internalError = true;
      // if we have an error while resuming, it is probably due to a problem
      // parsing the previous step in the localStorage. We remove it to restart
      // a flow from the beginning.
      localStorage.removeItem(FRAuth.previousStepKey);
      throw e;
    }
  }

  async loadStage(stage: string, step: FRStep) {
    console.debug('Rendering stage', stage);
    let component;
    if (stage in LoginPageComponent.stageComponentMap) {
      component = LoginPageComponent.stageComponentMap[stage];
    } else {
      console.debug('No stage component mapping found, rendering default login form');
      component = LoginFormComponent;
    }
    console.debug('Rendering stage component', component);
    const unsupportedCallbacks = this.getCurrentUnsupportedCallbacks(step);
    console.debug('Rendering if no unsupported in following list', unsupportedCallbacks);
    if (unsupportedCallbacks.length === 0) {
      await this.renderStageComponent(component, step);
    } else {
      this.messageService.add({
        severity: 'error',
        // summary: this.translate.instant("ERROR"),
        detail: this.translate.instant('DEFAULT_ERROR') +
          this.translate.instant('UNSUPPORTED_CALLBACKS') +
          unsupportedCallbacks.map(c => c.payload.type).join(', '),
      });
      console.debug('Found unsupported callbacks, not rendering');
    }
  }

  async renderStageComponent(component: Type<StageComponent>, step: FRStep) {
    const componentRef = this.stage.viewContainerRef.createComponent<StageComponent>(component);
    componentRef.instance.loadCallbacks(step);
    // (see https://angular.io/guide/inputs-outputs)
    // Input data/events - LoginPage (parent) -> StageComponent (child)
    componentRef.instance.step = step;
    componentRef.instance.loginFailed = this.loginFailed;
    componentRef.instance.internalError = this.internalError;
    componentRef.instance.amDown = this.amDown;
    componentRef.instance.authenticating = this.authenticating;
    componentRef.instance.loginSuccess = this.loginSuccess;
    // Output data/events - StageComponent (child) -> LoginPage (parent)
    componentRef.instance.loginWithEmitter.subscribe(
      (event) => {
        console.debug('loginWithEmitter login-page event');
        let { service, resetStep } = event;
        this.loginWith(service, resetStep);
      });
  }

  foundCallbacks(step: FRStep): boolean {
    const stepCallbacks = step?.callbacks;
    return stepCallbacks?.length > 0;
  }

  getCurrentUnsupportedCallbacks(step: FRStep): FRCallback[] {
    console.debug('Filtering', step.callbacks);
    let callbacks: FRCallback[];
    if (step.callbacks) {
      callbacks = step.callbacks.filter(
        (callback) => !Object.keys(StageComponent.callbackComponentMap).includes(callback.payload.type),
      );
    } else {
      callbacks = [];
    }
    console.debug('Current unsupported callbacks', callbacks);
    return callbacks;
  }

  clearStage() {
    // clearing previous view, with previous stage (including callbacks)
    this.stage?.viewContainerRef.clear();
  }

}
