import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NameCallback } from '@forgerock/javascript-sdk';
import { CallbackComponent } from '../../callback.component';

@Component({
  selector: 'app-callback-name',
  templateUrl: './name.component.html',
  styleUrls: [ './name.component.scss' ],
})
export class NameComponent extends CallbackComponent {
  fc = new FormControl('', Validators.required);
  override callback: NameCallback;

  onInputChange(): void {
    if (this.fc.valid) {
      this.callback.setName(this.fc.value!);
    }
  }
}
