import { Injectable } from '@angular/core';
import { externalSettings } from './application-settings';

@Injectable({
    providedIn: 'root',
})
export class ApplicationSettingsService {
    private readonly environmentValue: string;
    private readonly productionValue: string;
    private readonly amTimeoutSecondsValue: string;
    private readonly amRealmValue: string;
    private readonly amBaseUrlValue: string;
    private readonly apiValue: string;
    private readonly redirectUriValue: string;
    private readonly registerLinkValue: string;
    private readonly loginpageLinkValue: string;
    private readonly faqLinkValue: string;
    private readonly otherMutLink: string;
    private readonly otherMutLink1: string;

    constructor() {
        this.environmentValue = this.getPropertyValue('environment');
        this.productionValue = this.getPropertyValue('production');
        this.amTimeoutSecondsValue = this.getPropertyValue('amTimeoutSeconds');
        this.amRealmValue = this.getPropertyValue('amRealm');
        this.amBaseUrlValue = this.getPropertyValue('amBaseUrl');
        this.apiValue = this.getPropertyValue('api');
        this.redirectUriValue = this.getPropertyValue('redirectUri');
        this.registerLinkValue = this.getPropertyValue('registerLink');
        this.loginpageLinkValue = this.getPropertyValue('loginpageLink');
        this.faqLinkValue = this.getPropertyValue('faqLink');
        this.otherMutLink = this.getPropertyValue('otherMutLink');
        this.otherMutLink1 = this.getPropertyValue('otherMutLink1');
    }

    public get environment(): string {
        return this.environmentValue;
    }


    public get production(): string {
        return this.productionValue;
    }

    public get amTimeoutSeconds(): string {
        return this.amTimeoutSecondsValue;
    }

    public get amRealm(): string {
        return this.amRealmValue;
    }

    public get am_Base_Url(): string {
        return this.amBaseUrlValue;
    }

    public get api(): string {
        return this.apiValue;
    }

    public get redirect_uri(): string {
        return this.redirectUriValue;
    }

    public get register_link(): string {
        return this.registerLinkValue;
    }

    public get login_page_link(): string {
        return this.loginpageLinkValue;
    }

    public get faq_link(): string {
        return this.faqLinkValue;
    }

    public get other_mut_Link(): string {
        return this.otherMutLink;
    }

    public get other_mut_Link1(): string {
        return this.otherMutLink1;
    }

    public getPropertyValue(propertyName: string): string {
        return externalSettings[propertyName];
    }

}
