import { Component } from '@angular/core';
import {StageComponent} from "../../../stage.component";

@Component({
  selector: 'app-collect-device-profile',
  templateUrl: './collect-device-profile.component.html',
  styleUrls: ['./collect-device-profile.component.scss']
})
export class CollectDeviceProfileComponent extends StageComponent {

}
