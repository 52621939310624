import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationSettingsService } from '../config/application-settings.service';
import { SubSink } from '../utils/subsink';


@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: [ './page-not-found.component.scss' ],
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
    fcpUrl: string;
    private subSink = new SubSink();
    private urllanguage: string;

    constructor(translate: TranslateService, private applicationSettingsServices: ApplicationSettingsService) {
        this.subSink.sink = translate.onLangChange.subscribe((lang) => {
            this.urllanguage = lang.lang;
        });
        this.fcpUrl = applicationSettingsServices.login_page_link;
    }

    ngOnInit(): void {

    }

    ngOnDestroy() {
        this.subSink.unsubscribe();
    }

}
