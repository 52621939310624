<div>
  <div class="card col-12">
    <div class="flex flex-column">
        <div>
          <div class="pi pi-inbox big-icon"></div>
          <div>
            <h3>{{ "PASSWORDRESET_UNABLE_TO_SAVE_PASSWORD" | translate }}</h3>
          </div>
        </div>
        <a [attr.href]="loginpageLink">
          <button pButton class="w-full"[label]="'BACK_TO_THE_HOME_PAGE' | translate"></button>
        </a>
    </div>
  </div>
</div>
