import { Component } from '@angular/core';
import {StageComponent} from "../../../stage.component";

@Component({
  selector: 'app-error-location-outside-belgium',
  templateUrl: './error-location-outside-belgium.component.html',
  styleUrls: ['./error-location-outside-belgium.component.scss']
})
export class ErrorLocationOutsideBelgiumComponent extends StageComponent {


}
