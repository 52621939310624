<div>
  <div class="card col-12">
    <div class="flex flex-column">
      <h2>{{ "USERNAMEPASSWORD_COLLECTDEVICEPROFILE_TITLE" | translate }}</h2>
      <p>{{ "USERNAMEPASSWORD_COLLECTDEVICEPROFILE_INSTRUCTIONS_1" | translate }}</p>
      <p>{{ "USERNAMEPASSWORD_COLLECTDEVICEPROFILE_INSTRUCTIONS_2" | translate }}</p>
      <p>{{ "USERNAMEPASSWORD_COLLECTDEVICEPROFILE_INSTRUCTIONS_3" | translate }}</p>
      <form (ngSubmit)="loginWith()" ngNativeValidate>
        <ng-template appCallback></ng-template>
      </form>
    </div>
  </div>
</div>
