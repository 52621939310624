<head>
  <title></title>
<script>
  var _mtm = window._mtm = window._mtm || [];
  _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
  (function() {
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/mloz.matomo.cloud/container_k3zpuyOi'+[matomoCode]+'.js'; s.parentNode.insertBefore(g,s);
  })();
</script>
</head>
<div class="grid mt-5">

  <div class="card col-6 col-offset-3">
    <app-banner></app-banner>
    <!-- When in LoginFailure step, no stage are rendered. -->
    <!-- Usually login buttons are rendered in stage components, but in LoginFailure -->
    <!-- no stages are returned, so we show a retry button to the users to restart -->
    <!-- the authentication flow. -->
    <button pButton
            *ngIf="loginFailed"
            (click)="loginWith()"
            class="w-full"
            [label]="'RETRY' | translate"
            [disabled]="authenticating || loginSuccess"
    ></button>
  </div>
  <div class="col-6 col-offset-3 px-0">
    <ng-template appStage></ng-template>
  </div>
</div>



