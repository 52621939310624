<div>
  <div class="card col-12">
    <div class="flex flex-column">
      <p>{{ "PASSWORDRESET_COLLECTEMAIL_TITLE" | translate }}</p>
      <p>{{ "PASSWORDRESET_COLLECTEMAIL_INSTRUCTIONS" | translate }}</p>
      <form (ngSubmit)="loginWith()" ngNativeValidate>
        <ng-template appCallback></ng-template>
        <div class="button-container">
          <button [disabled]="authenticating || loginSuccess"
                  [label]="'PASSWORDRESET_COLLECTEMAIL_BUTTON' | translate"
                  class="w-full"
                  pButton
                  type="submit"
          ></button>
          <button (click)="loginWith('', true)"
                  [label]="'BACK_TO_THE_HOME_PAGE' | translate"
                  class="w-full"
                  pButton styleClass="p-button-secondary">

          </button>
        </div>
      </form>
    </div>
  </div>
</div>
