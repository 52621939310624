import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ApplicationSettingsService } from '../config/application-settings.service';
import { StageComponent } from '../stage.component';
import { SubSink } from '../utils/subsink';

interface Option {
  name: string,
  code: string
}

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: [ './login-form.component.scss' ],

  host: {
    '(document:keypress)': 'handleKeyboardEvent($event)',
  },

})
export class LoginFormComponent extends StageComponent implements OnInit {
  urllanguage: string;
  registerLink: string;
  faqLink: string;
  otherMutLink: string;
  otherMutLink1: string;
  union: string;
  private subSink = new SubSink();

  constructor(messageService: MessageService, route: ActivatedRoute, translate: TranslateService, private applicationSettingsServices: ApplicationSettingsService) {
    super(messageService, route, translate);
    this.urllanguage = this.translate.currentLang;
    this.registerLink = this.applicationSettingsServices.register_link;
    this.faqLink = this.applicationSettingsServices.faq_link;
    this.otherMutLink = this.applicationSettingsServices.other_mut_Link;
    this.otherMutLink1 = this.applicationSettingsServices.other_mut_Link1;
    this.union = this.applicationSettingsServices.amRealm;
  }

  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 13)
      this.loginWith();
  }

  ngOnInit(): void {
    this.subSink.sink = this.translate.onLangChange.subscribe((lang) => {
      console.info('language changed: ', lang.lang);
      this.urllanguage = lang.lang;
    });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
