import { Component, OnInit } from '@angular/core';
import {StageComponent} from "../../../stage.component";
import {MessageService} from "primeng/api";
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ApplicationSettingsService} from "../../../config/application-settings.service";

@Component({
  selector: 'app-fas-error-user-not-found',
  templateUrl: './error-user-not-found.component.html',
  styleUrls: ['./error-user-not-found.component.scss']
})
export class ErrorUserNotFoundComponent extends StageComponent {

  constructor(
    messageService: MessageService,
    route: ActivatedRoute,
    translate: TranslateService,
    private applicationSettingsServices : ApplicationSettingsService
  ) {
    super(messageService, route, translate)
  }
  registerLink : string = this.applicationSettingsServices.register_link;
}
