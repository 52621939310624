import { Component } from '@angular/core';
import {StageComponent} from "../../../stage.component";

@Component({
  selector: 'app-password-reset-token-sent',
  templateUrl: './token-sent.component.html',
  styleUrls: ['./token-sent.component.scss']
})
export class TokenSentComponent extends StageComponent {

}
