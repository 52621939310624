import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Message } from 'primeng/api';
import { BaseComponent } from '../base.component';
import { ApplicationSettingsService } from '../config/application-settings.service';
import { SubSink } from '../utils/subsink';


@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: [ './banner.component.scss' ],
})
export class BannerComponent extends BaseComponent implements OnInit, OnDestroy {
  urllanguage: string = 'fr';
  msgs: Message[] = [];
  @Input() title!: string;
  faqLink: string;
  registerLink: string;
  isProd: boolean;
  private subSink = new SubSink();

  constructor(translate: TranslateService, private applicationSettingsServices: ApplicationSettingsService) {
    super(translate);
    this.urllanguage = this.translate.currentLang;
    this.isProd = applicationSettingsServices?.environment?.includes('PROD') || false;
    this.faqLink = this.applicationSettingsServices.faq_link;
    this.registerLink = this.applicationSettingsServices.register_link;
  }

  ngOnInit(): void {
    this.subSink.sink = this.translate.onLangChange.subscribe((lang) => {
      this.urllanguage = this.translate.currentLang;
    });
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

}
