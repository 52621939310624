<div>
  <div class="card col-12">
    <div class="flex flex-column">
      <p>{{ "PASSWORDRESET_SETPASSWORD_TITLE" | translate }}</p>
      <div>
        {{ "PASSWORDRESET_SETPASSWORD_INSTRUCTIONS_1" | translate }}
        <ul>
          <li>{{ "PASSWORDRESET_SETPASSWORD_INSTRUCTIONS_2" | translate }}</li>
          <li>{{ "PASSWORDRESET_SETPASSWORD_INSTRUCTIONS_3" | translate }}</li>
          <li>{{ "PASSWORDRESET_SETPASSWORD_INSTRUCTIONS_4" | translate }}</li>
          <li>{{ "PASSWORDRESET_SETPASSWORD_INSTRUCTIONS_5" | translate }}</li>
        </ul>
      </div>
      <form (ngSubmit)="loginWith()" ngNativeValidate>
        <ng-template appCallback></ng-template>
        <button pButton
                type="submit"
                class="w-full"
                [label]="'PASSWORDRESET_SETPASSWORD_BUTTON' | translate"
                [disabled]="authenticating || loginSuccess"
        >
        </button>
      </form>
    </div>
  </div>
</div>
