<div>
  <div class="card col-12">
    <div class="flex flex-column">
      <form ngNativeValidate>
        <ng-template appCallback></ng-template>
        <div>
          <div class="pi pi-inbox big-icon"></div>
          <div>
            <h3>{{ "PASSWORDRESET_TOKENSENT_CHECK_YOUR_MAIL_BOX" | translate }}</h3>
          </div>
          <p>{{'PASSWORDRESET_TOKENSENT_CONFIRMATION_INSTRUCTIONS_1' | translate }}</p>
          <p>{{'PASSWORDRESET_TOKENSENT_CONFIRMATION_INSTRUCTIONS_2' | translate }}</p>
          <p>{{'PASSWORDRESET_TOKENSENT_CONFIRMATION_INSTRUCTIONS_3' | translate }}</p>
        </div>
        <button pButton
                (click)="loginWith('', true)"
                class="w-full"
                [label]="'BACK_TO_THE_HOME_PAGE' | translate"
        >
        </button>
      </form>
    </div>
  </div>
</div>
