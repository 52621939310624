<div>
  <div class="card col-12">
    <div class="flex flex-column">
      <p>{{ "FAS_USER_NOT_FOUND_TITLE" | translate }}</p>
      <p>{{ "FAS_USER_NOT_FOUND_INSTRUCTIONS_1" | translate}}</p>
      <p>
        {{ "FAS_USER_NOT_FOUND_INSTRUCTIONS_2" | translate}}
        <a id="noaccount" class="text-indigo-500 focus:text-teal-900 focus:text-secondary my-2 cursor-pointer" [attr.href]="registerLink">
          {{'FAS_USER_NOT_FOUND_REGISTER_ACCOUNT_LINK' | translate}}
        </a>
      </p>
      <p>
        {{ "FAS_USER_NOT_FOUND_INSTRUCTIONS_3" | translate}}
      </p>
      <form (ngSubmit)="loginWith()" ngNativeValidate>
        <ng-template appCallback></ng-template>
        <button pButton
                type="submit"
                class="w-full"
                (click)="loginWith('',true)"
                [label]="'RETRY' | translate"
        >
        </button>
      </form>
    </div>
  </div>
</div>
<style>
  #noaccount {
    font-weight: bolder;
  }
</style>
