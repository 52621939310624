<div>
  <div class="card col-12">
    <div class="flex flex-column">
      <form ngNativeValidate>
        <ng-template appCallback></ng-template>
        <div>
          <div class="pi pi-inbox big-icon"></div>
          <div>
            <h3>{{ "RECOVERUSERNAME_ERROR_SENDING_MAIL" | translate }}</h3>
          </div>
        </div>
        <button (click)="loginWith('', true)"
                [label]="'BACK_TO_THE_HOME_PAGE' | translate"
                class="w-full"
                pButton>
        </button>
      </form>
    </div>
  </div>
</div>
