import { Component } from '@angular/core';
import { SelectIdPCallback } from '@forgerock/javascript-sdk';
import { IdPValue } from '@forgerock/javascript-sdk/lib/fr-auth/callbacks/select-idp-callback';
import { Observable, of } from 'rxjs';
import { SubSink } from 'src/app/utils/subsink';
import { CallbackComponent } from '../../callback.component';
import { ApplicationSettingsService } from '../../config/application-settings.service';

@Component({
    selector: 'app-select-id-p',
    templateUrl: './select-id-p.component.html',
    styleUrls: [ './select-id-p.component.scss' ],
})
export class SelectIdPComponent extends CallbackComponent {
    override callback: SelectIdPCallback;
    providers: Observable<IdPValue[]> = of([]);
    mutNum: string;
    private subSink = new SubSink();
    

    constructor(private applicationSettings: ApplicationSettingsService) {
        super();
        this.mutNum = applicationSettings.getPropertyValue('amRealm');
    }

    override ngOnInit(): void {
  
        console.debug('SelectIdpComponent providers ', this.callback.getProviders());
        if (this.callback?.getProviders().some(e => e.provider === 'localAuthentication')) {
            // default to local authentication if found
            this.callback.setInputValue('localAuthentication');
        }
        
    }

    selectIdp(idp: string): void {
        console.debug('SelectIdpComponent callback: selected idp ', idp);
        this.callback.setInputValue(idp);
        this.loginWithEmitter.emit({ resetStep: false, service: '' });
    }
}
