<div style="display: flex;justify-content: center;font-weight:bolder">


    <span> {{'CURRENT_MUT_LOGIN_HEADER'|translate : {
    mutType: (mutNum === '500' ? 'MUTUALITY.LIBRES' : mutNum === '200' ? 'MUTUALITY.NEUTRES' : mutNum === '400' ? 'MUTUALITY.LIBERALES' : '') | translate,
    mutNumber: mutNum
} }}
</span>

   
</div>
<div (click)="selectIdp('fas')" class="fas-login-container">

    <span style="margin-right:12px">{{'LOGIN_WITH_CSAM'|translate}}</span>
    <!-- itsme logo -->
    <svg color="#ff4612" fill="none" height="40px" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.28 57.34C.91 53.97-.837 47.446.396 42.844l6.728-25.108c1.233-4.603 6.01-9.38 10.613-10.613L42.844.395C47.446-.838 53.97.91 57.34 4.28l18.38 18.38c3.37 3.362 5.116 9.893 3.894 14.495l-6.737 25.108c-1.233 4.603-6.01 9.38-10.613 10.613l-25.108 6.737C32.554 80.847 26.03 79.1 22.66 75.73L4.28 57.34z"
            fill="currentColor"></path>
        <path
            d="M49.094 28.807c-2.389-.816-3.906-1.13-3.798-2.545.084-1.103.92-1.67 2.453-1.544 1.118.086 1.795.549 2.136 1.246a2.647 2.647 0 0 0 2.323 1.473c2.227.049 3.584-2.538 2.188-4.276-1.255-1.562-3.32-2.618-6.23-2.84-5.11-.393-8.33 1.892-8.63 5.782-.32 4.166 2.48 5.293 6.72 6.724 2.895.933 3.612 1.385 3.528 2.602-.09 1.18-1.125 1.765-2.697 1.652-1.31-.101-2.115-.62-2.494-1.398-.426-.882-1.38-1.37-2.354-1.39a2.617 2.617 0 0 0-1.947.786s-1.102 1.233-3.253 1.233c-1.563-.042-2.948-.883-2.948-3.072l.053-7.322a3.138 3.138 0 1 0 1.632-5.81 3.056 3.056 0 0 0-1.597.464l.004-2.689a1.717 1.717 0 0 0-1.715-1.726h-2.33a1.716 1.716 0 0 0-1.715 1.707l-.102 15.72c-.152 1.956-1.504 2.708-3.017 2.719-1.605 0-3.047-.84-3.047-3.072l.108-9.925a1.134 1.134 0 0 0-1.134-1.142h-3.487a1.134 1.134 0 0 0-1.133 1.125l-.11 11.327c-.04 4.721 3.68 6.845 8.803 6.845 2.144 0 3.615-.864 4.632-2.066 1.3 1.404 3.332 2.066 5.791 2.066 2.459 0 4.298-.322 6.124-1.358 1.27.747 2.89 1.215 4.852 1.365 5.306.408 8.535-1.985 8.853-6.122.277-3.69-2.495-5.17-6.462-6.54zM19.628 19.65a3.138 3.138 0 1 0-3.127-3.11 3.13 3.13 0 0 0 3.127 3.11zm36.84 23.117c-5.142 0-9.002 2.893-10.065 7.778-.29-4.576-3.209-7.32-7.511-7.32-3.002 0-4.965 1.348-6.118 3.156-1.31-2.03-3.502-3.156-6.235-3.156-2.536 0-10.544-.308-10.544 9.98v7.489a2.81 2.81 0 1 0 5.618 0v-8.226c0-2.386 1.347-4 3.579-4 2.04 0 3.23 1.422 3.23 3.97v8.271a2.81 2.81 0 0 0 5.62 0v-8.933c.22-2 1.462-3.309 3.462-3.309 2.155 0 3.309 1.423 3.309 3.97v8.273a2.81 2.81 0 1 0 5.617 0v-4.398c1.102 4.794 4.962 7.666 10.193 7.666 2.459 0 4.792-.752 6.488-1.985.06-.042.115-.084.172-.126.057-.041.133-.092.194-.143 0 0 0-.018.014-.024.092-.075.191-.148.28-.22 1.645-1.505.699-4.268-1.511-4.56a2.646 2.646 0 0 0-2.52 1.103c-.643.838-1.752 1.31-3.27 1.31-2.602 0-4.139-1.616-4.258-4.14h12.163a1.833 1.833 0 0 0 1.832-1.832v-.454c0-6.354-3.863-10.14-9.738-10.14zm-4.258 8.6c.157-2.602 1.764-4.1 4.219-4.1 2.602 0 3.864 1.617 3.983 4.1H52.21z"
            fill="#fff"></path>
    </svg>
    <span style="margin-left:12px;margin-right:12px">{{'OR'|translate}}</span>
    <!-- eID logo -->
    <svg height="30px" viewBox="0 0 154.1 110.94" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <style>
                .cls-1 {
                    fill: #87cea3;
                }

                .cls-2,
                .cls-3,
                .cls-4 {
                    fill-rule: evenodd;
                }

                .cls-3 {
                    fill: #f9e819;
                }

                .cls-4 {
                    fill: #e2141a;
                }

                .cls-5 {
                    fill: #fff;
                }
            </style>
        </defs>
        <title>logo</title>
        <g data-name="Layer 2" id="Layer_2">
            <g data-name="Layer 1" id="Layer_1-2">
                <rect class="cls-1" height="79.93" rx="23.75" width="138.05" y="15.33" />
                <circle cx="21.16" cy="72.21" id="path4138" r="3.6" />
                <path class="cls-2"
                    d="M26,74.52V33.79h6.88V48.17c10.29-2.89,14.85,1.15,17.87,7,4.94-12.13,24.68-11.8,23.67,8.48H56.69c1.23,9.49,11.51,7.62,14.83,5l2,4.58c-12.12,7.94-20.93.61-23.14-5.21C47.54,71.77,41.85,80.19,26,74.52Z"
                    id="path4144" />
                <path class="cls-3" d="M32.92,54.07c16.43-6.41,15.84,21.12,0,16.26Z" id="path4146" />
                <path class="cls-4" d="M56.28,58.94c0-9.06,11.24-8.69,11.24,0Z" id="path4150" />
                <rect class="cls-5" height="43" width="6.8" x="81.83" y="33.79" />
                <path class="cls-5"
                    d="M91.31,33.79h12.1c12.14,0,17.09,7,17.09,20.53,0,14.55-4.65,22.47-17.8,22.47H91.31ZM98,71.27h4.59c7.56,0,11.09-5.11,11.09-16.87,0-10.41-3.52-15-11-15H98Z" />
                <path
                    d="M115.39,0H99.28V12h16.11A26.75,26.75,0,0,1,142.1,38.72v33.5a26.75,26.75,0,0,1-26.71,26.72H99.28v12h16.11A38.76,38.76,0,0,0,154.1,72.22V38.72A38.76,38.76,0,0,0,115.39,0Z" />
            </g>
        </g>
    </svg>
</div>
<span class="text-divider">
    {{ "OR" | translate }}
</span>