import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { externalSettings } from './app/config/application-settings';

(async () => {
  if (environment.production) {
    enableProdMode();
  }
  const response = await fetch('env-specific-files/environment.json?prevent-caching=' + Math.random().toString());
  const config = await response.json();

  for (const propertyName in config) {
    if (config.hasOwnProperty(propertyName)) {
      externalSettings[propertyName] = config[propertyName];
    }
  }
  console.log('Main.environment:', environment);
  console.log('Main.externalSettings:', externalSettings);

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
})();
