<div>
  <div class="card col-12">
    <div class="flex flex-column">
      <p>{{ "USERNAMEPASSWORD_COLLECTDEVICEPROFILE_ERROR_LOCATIONNOTSHARED_TITLE" | translate }}</p>
      <form (ngSubmit)="loginWith()" ngNativeValidate>
        <ng-template appCallback></ng-template>
        <button pButton
                type="submit"
                class="w-full"
                (click)="loginWith('',true)"
                [label]="'RETRY' | translate"
        >
        </button>
      </form>
    </div>
  </div>
</div>
