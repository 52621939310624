import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ApplicationSettingsService } from 'src/app/config/application-settings.service';
import {StageComponent} from "../../../stage.component";

@Component({
  selector: 'app-password-reset-invalid-password',
  templateUrl: './invalid-password.component.html',
  styleUrls: ['./invalid-password.component.scss']
})
export class InvalidPasswordComponent extends StageComponent {
  constructor (
    messageService: MessageService,
    route: ActivatedRoute,
    translate: TranslateService,
    private applicationSettingsServices : ApplicationSettingsService
  ) {
    super (messageService, route, translate);
  }
  loginpageLink : string = this.applicationSettingsServices.login_page_link;
}
