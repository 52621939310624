import { Component } from '@angular/core';
import { StageComponent } from '../../../stage.component';

@Component({
  selector: 'app-recover-username-collect-email',
  templateUrl: './username-collect-email.component.html',
  styleUrls: [ './username-collect-email.component.scss' ],
})
export class UsernameCollectEmailComponent extends StageComponent {

}
