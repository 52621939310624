import { HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpLangInterceptor implements HttpInterceptor {


  intercept(req: HttpRequest<any>, next: HttpHandler) {

    const newReq = req.clone({
      params: (req.params ? req.params : new HttpParams())
        .set('lang', localStorage.getItem('locale') || 'fr'),
    });

    return next.handle(newReq);
  }
}
