import {Component} from "@angular/core"
import {CallbackComponent} from "../../callback.component"
import {MessageService} from "primeng/api"
import {TranslateService} from "@ngx-translate/core"
import {TextOutputCallback} from "@forgerock/javascript-sdk";

@Component({
  selector: 'app-text-output',
  templateUrl: './text-output.component.html',
  styleUrls: ['./text-output.component.scss']
})
export class TextOutputComponent extends CallbackComponent {

  override callback: TextOutputCallback

  message: string
  messageType: number
  typeClasses = new Map<number,string>([
    [0,"info"],
    [1,"warn"],
    [2,"error"]
  ])

  constructor(private translate: TranslateService, private messageService: MessageService) {
    super()
  }

  override ngOnInit(): void {
    this.message = this.callback.getMessage()
    this.messageType = Number(this.callback.getMessageType()) || 0
    console.debug("messageType", this.messageType)
    if (this.messageType === 1 || this.messageType === 2) {
      this.messageService.add({
        severity: this.typeClasses.get(this.messageType),
        // @ts-ignore
        summary: this.translate.instant(this.typeClasses.get(this.messageType).toUpperCase()),
        detail: this.translate.instant((this.message as string).toUpperCase())
      })
    }
  }
}


